<template>
    <div v-if="!loading">
        <h4 class="d-flex justify-content-between align-items-center font-weight-bold py-3 mb-4">Редактирование - Пользователь <i class="ion ion-ios-lock mx-1 text-danger" v-if="!user.is_active"></i></h4>
        <b-card no-body>
            <b-card-body class="pb-2">
                <form-row-edit-text v-model="$v.user.name.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.user.name" label="ФИО" />
                <form-row-edit-text v-model="user.organization_title" disabled label="Название организации" />
                <form-row-edit-text v-model="user.organization_inn" disabled label="ИНН организации" />
                <form-row-edit-text v-model="$v.user.email.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.user.email" label="Email" />
                <form-row-edit-text type="password" v-model="$v.user.password.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.user.password" label="Пароль" />
                <form-row-edit-text v-model="$v.user.phone.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.user.phone" label="Телефон" />
                <form-row-single-select label="Роль" v-model="$v.user.role.$model" :v="$v.user.role" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :options="$globalDictionaries.userRoles" label-field="title" />
            </b-card-body>
            <b-card-footer v-if="userRoleIsPermitted([AdminRole.SUPER])">
                <div class="text-right">
                    <b-button :disabled="$v.$invalid || !$v.$anyDirty" type="submit" variant="primary" @click="onSubmit"> Сохранить</b-button>&nbsp;
                    <b-button variant="default" @click="onCancel"> Отмена</b-button>
                </div>
            </b-card-footer>
        </b-card>
    </div>
</template>

<script>
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
    name: 'userEdit',
    metaInfo: {
        title: 'Редактирование - Пользователь',
    },
    data() {
        return {
            user: {
                name: '',
                organization_title: '',
                organization_inn: '',
                email: '',
                password: '********',
                phone: '',
                role: '',
            },
            loading: true,
        };
    },
    validations: {
        user: {
            name: { required },
            email: { required, email },
            password: { required, minLength: minLength(8) },
            phone: { required },
            role: { required },
        },
    },
    async mounted() {
        await this.fillData();
        this.loading = false;
    },
    methods: {
        async fillData() {
            this.user = await this.$api.users.get(this.$route.params.id);
        },
        async onSubmit() {
            if (await this.$api.users.put(this.$route.params.id, this.user)) {
                this.onCancel();
            }
        },
        onCancel() {
            this.$router.push({ name: 'UsersList' });
        },
    },
};
</script>
