<template>
    <div>
        <h4 class="d-flex justify-content-between align-items-center font-weight-bold py-3 mb-4">Создание - Пользователь</h4>
        <b-modal id="email-input-modal" v-model="showEmailInputModal" centered no-close-on-backdrop no-close-on-esc hide-header-close title="Введите Email">
            <form-row-edit-text label="Email" :v="$v.user.email" v-model="$v.user.email.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" />
            <template slot="modal-footer">
                <b-overlay :show="isEmailChecking" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button class="mr-2 text-uppercase" variant="outline-secondary" @click="onCancel">Отмена </b-button>
                </b-overlay>
                <b-overlay :show="isEmailChecking" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="!$v.user.email.$model || $v.user.email.$invalid" class="text-uppercase" variant="primary" @click="handleEmailModalOk">Добавить</b-button>
                </b-overlay>
            </template>
        </b-modal>
        <b-card no-body v-if="isEmailChecked && !showEmailInputModal">
            <b-card-body class="pb-2">
                <form-row-edit-text v-model="$v.user.name.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.user.name" label="ФИО" />
                <form-row-edit-text v-model="$v.user.email.$model" disabled :v="$v.user.email" label="Email" />
                <form-row-organization-select label="Организация" v-model="$v.user.organization_id.$model" :v="$v.user.organization_id" />
                <form-row-edit-text type="password" v-model="$v.user.password.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.user.password" label="Пароль" />
                <form-row-edit-text v-model="$v.user.phone.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.user.phone" label="Телефон" />
                <form-row-single-select label="Роль" v-model="$v.user.role.$model" :v="$v.user.role" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :options="$globalDictionaries.userRoles" label-field="title" />
            </b-card-body>
            <b-card-footer v-if="userRoleIsPermitted([AdminRole.SUPER])">
                <div class="text-right">
                    <b-button :disabled="$v.$invalid" type="submit" variant="primary" @click="onSubmit"> Сохранить</b-button>&nbsp;
                    <b-button variant="default" @click="onCancel"> Отмена</b-button>
                </div>
            </b-card-footer>
        </b-card>
    </div>
</template>

<script>
import { email, minLength, required } from "vuelidate/lib/validators";
import FormRowOrganizationSelect from '@/elements/form-row-organization-select.vue';
import store from '@/store';

export default {
    name: 'userCreate',
    components: { FormRowOrganizationSelect },
    metaInfo: {
        title: 'Создание - Пользователь',
    },
    data() {
        return {
            user: {
                name: '',
                organization_id: null,
                email: '',
                password: '',
                phone: '',
                role: '',
            },
            isDataSending: false,
            isEmailChecking: false,
            isEmailChecked: false,
            showEmailInputModal: false,
        };
    },
    validations: {
        user: {
            name: { required },
            email: { required, email },
            organization_id: { required },
            password: { required, minLength: minLength(8) },
            phone: { required },
            role: { required },
        },
    },
    async mounted() {
        this.showEmailInputModal = true;
    },
    methods: {
        async onSubmit() {
            if (await this.$api.users.post(this.user)) {
                await store.dispatch('showSuccessToast', 'Пользователь успешно создан').then(() => {});
                this.onCancel();
            }
        },
        onCancel() {
            this.$router.push({ name: 'UsersList' });
        },
        async handleEmailModalOk() {
            this.isEmailChecking = true;
            if (await this.$api.users.checkEmail(this.user.email)) {
                this.isEmailChecked = true;
                this.isEmailChecking = false;
                this.showEmailInputModal = false;
            } else {
                this.isEmailChecked = false;
                this.isEmailChecking = false;
            }
        },
    },
};
</script>
