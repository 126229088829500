<template>
    <div>
        <loading-card v-show="loading"></loading-card>
        <b-card v-show="!loading" no-body>
            <filter-block>
                <template #header-left>
                    <span class="text-large font-weight-bold">Пользователи</span>
                </template>
                <template #header-right>
                    <b-button v-if="userRoleIsPermitted([AdminRole.SUPER])" :to="{ name: 'UserCreate' }" class="my-1 mx-1" size="sm" variant="primary">Создать пользователя </b-button>
                </template>
                <template #filters>
                    <filter-text v-model="filter.name" label="ФИО" placeholder="Введите ФИО" @input="onFilterChange" />
                    <filter-text v-model="filter.email" label="Email" placeholder="Введите email" @input="onFilterChange" />
                    <filter-text v-model="filter.organization" label="Название организации/ИНН/рег.номер" placeholder="Введите название организации/ИНН/рег.номер" @input="onFilterChange" />
                </template>
            </filter-block>
            <b-card-body v-show="!loading">
                <div class="table-responsive">
                    <vuetable
                        ref="vuetable"
                        :append-params="filter"
                        :css="vuetable2Config.table"
                        :fields="columns"
                        :http-fetch="fetchData"
                        :load-on-start="false"
                        :per-page="perPage"
                        :query-params="tableQueryParams"
                        :sort-order="paginationInfo.sortOrder"
                        api-url="/users"
                        data-path="data"
                        no-data-template="Нет данных"
                        pagination-path="meta"
                        @vuetable:pagination-data="onPaginationData">
                        <div slot="id" slot-scope="props">
                            {{ props.rowData.id }}
                            <template v-if="props.rowData.is_deleted">
                                <i class="ion ion-ios-close mx-1 text-danger"></i>
                            </template>
                            <template v-else>
                                <i v-if="!props.rowData.is_active" class="ion ion-ios-lock mx-1 text-danger"></i>
                            </template>
                        </div>
                        <div slot="name" slot-scope="props">
                            <template v-if="!props.rowData.is_deleted">
                                <router-link :to="{ name: 'UserEdit', params: { id: props.rowData.id } }">
                                    {{ props.rowData.name }}
                                </router-link>
                            </template>
                            <template v-else>
                                {{ props.rowData.name }}
                            </template>
                            <br />
                            <strong>Роль</strong>: {{ props.rowData.role_title ?? '&mdash;' }}
                        </div>
                        <div slot="organization_title" slot-scope="props">
                            <router-link :to="{ name: 'OrganizationEdit', params: { id: props.rowData.organization_id } }">
                                {{ props.rowData.organization_title }}
                            </router-link>
                        </div>
                        <div slot="created_at" slot-scope="props" style="text-align: right">
                            {{ getDateFormat(props.rowData.created_at) }}
                        </div>
                        <div slot="actions" slot-scope="props" class="float-right">
                            <b-dropdown v-if="userRoleIsPermitted([AdminRole.SUPER])" id="dropdown-1" boundary="window" right size="sm" text="Действия">
                                <b-dropdown-item v-if="!props.rowData.is_deleted" :to="{ name: 'UserEdit', params: { id: props.rowData.id } }">Редактировать </b-dropdown-item>
                                <b-dropdown-item v-if="!props.rowData.is_deleted && props.rowData.is_active" variant="danger" @click="blockUser(props.rowData.id)">Блокировать </b-dropdown-item>
                                <b-dropdown-item v-if="!props.rowData.is_deleted && !props.rowData.is_active" @click="unblockUser(props.rowData.id)">Разблокировать </b-dropdown-item>
                                <b-dropdown-item v-if="!props.rowData.is_deleted" variant="danger" @click="deleteUser(props.rowData.id)">Удалить </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </vuetable>
                </div>
                <div class="float-right d-flex" style="margin-top: 10px">
                    <vuetable-pagination ref="pagination" :css="vuetable2Config.pagination" @vuetable-pagination:change-page="onChangePage" />
                </div>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import FilterBlock from '@/elements/filter-block';

export default {
    name: 'users-list',
    components: { FilterBlock },
    metaInfo: {
        title: 'Пользователи',
    },
    data() {
        return {
            columns: [
                { name: 'id', sortField: 'id', title: '#', width: '60px' },
                { name: 'name', sortField: 'name', title: 'ФИО' },
                { name: 'email', sortField: 'email', title: 'Email' },
                { name: 'phone', title: 'Телефон' },
                { name: 'organization_title', title: 'Организация' },
                { name: 'created_at', title: 'Дата регистрации в ИС' },
                { name: 'actions', title: 'Действия', width: '100px' },
            ],
            isFiltersVisible: false,
            filter: this.$store.state.users.filter,
            paginationInfo: this.$store.state.users.pagination,
            loading: true,
            perPage: 20,
            exportLoading: false,
        };
    },
    async mounted() {
        // await this.fillFilters();
        await this.fillData();
        this.loading = false;
    },
    methods: {
        async fillData() {
            this.loading = true;

            await this.$refs.vuetable.refresh();
            await this.$refs.vuetable.changePage(this.paginationInfo.page);
            this.loading = false;
        },
        fetchData(apiUrl, httpOptions) {
            return this.$http.get(apiUrl, httpOptions);
        },
        tableQueryParams(sortOrder, currentPage, perPage) {
            this.paginationInfo.page = currentPage;
            this.$store.commit('users/updatePagination', this.paginationInfo);
            return {
                sortField: sortOrder[0].field,
                sortOrder: sortOrder[0].direction,
                page: currentPage,
                per_page: perPage,
            };
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.paginationInfo.page = page;
            this.$refs.vuetable.changePage(page);
        },
        onFilterChange() {
            this.$store.commit('users/updateFilter', this.filter);
            this.$refs.vuetable.refresh();
        },
        blockUser(userId) {
            this.$confirmAction('Заблокировать пользователя #' + userId, 'Вы действительно хотите заблокировать пользователя с #' + userId, 'Заблокировать').then((value) => {
                if (value === true) {
                    this.loading = true;
                    this.$api.users.block(userId).then(() => {
                        this.$refs.vuetable.refresh().then(() => (this.loading = false));
                    });
                }
            });
        },
        unblockUser(userId) {
            this.$confirmAction('Разблокировать пользователя #' + userId, 'Вы действительно хотите разблокировать пользователя с #' + userId, 'Разблокировать').then((value) => {
                if (value === true) {
                    this.loading = true;
                    this.$api.users.unblock(userId).then(() => {
                        this.$refs.vuetable.refresh().then(() => (this.loading = false));
                    });
                }
            });
        },
        deleteUser(userId) {
            this.$confirmAction('Удалить пользователя #' + userId, 'Вы действительно хотите удалить пользователя с #' + userId, 'Удалить').then((value) => {
                if (value === true) {
                    this.loading = true;
                    this.$api.users.delete(userId).then(() => {
                        this.$refs.vuetable.refresh().then(() => (this.loading = false));
                    });
                }
            });
        },
    },
};
</script>
