<template>
    <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm="8" content-cols-lg="9" :description="description" :label="label" :label-for="id" :state="!!state" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <multiselect
            v-model="text"
            :options="organizations"
            :searchable="true"
            :loading="isLoading"
            :internal-search="false"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :placeholder="placeholder"
            :label="labelField"
            :track-by="trackBy"
            :disabled="disabled"
            :show-labels="false"
            :id="id"
            :options-limit="300"
            :max-height="600"
            :show-no-results="true"
            :hide-selected="true"
            @search-change="asyncFind">
            <template slot="singleLabel" slot-scope="props">
                <b-row>
                    <b-col>
                        <strong>ИНН</strong>: {{ props.option.inn }}<br />
                        <strong>Название</strong>: {{ props.option.name }}
                    </b-col>
                </b-row>
            </template>
            <template slot="option" slot-scope="props">
                <b-row>
                    <b-col>
                        <strong>ИНН</strong>: {{ props.option.inn }}<br />
                        <strong>Название</strong>: {{ props.option.name }}
                    </b-col>
                </b-row>
            </template>
        </multiselect>
    </b-form-group>
</template>

<script>
import debounceMixin from '@/mixins/debounce';

export default {
    name: 'form-row-organization-select',
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            default: null,
        },
        v: {
            type: Object,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        description: {
            type: String,
            default: '',
        },
        labelField: {
            type: String,
            default: 'name',
        },
        trackBy: {
            type: String,
            default: 'id',
        },
        options: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: 'Введите ИНН или название организации',
        },
        allowEmpty: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [debounceMixin],
    data() {
        return {
            isLoading: false,
            organizations: [],
            selectedOrganization: null,
        };
    },
    methods: {
        clearAll() {
            this.text = null;
        },
        async asyncFind(query) {
            this.debounce(async () => {
                this.isLoading = true;
                this.organizations = await this.$api.organizations.find({ query });
                this.isLoading = false;
            });
        },
    },
    computed: {
        text: {
            get() {
                return this.selectedOrganization ?? null;
            },
            set(value) {
                this.selectedOrganization = value;
                this.$emit('input', value.id);
            },
        },
        id() {
            return 'form_row_organization_select_' + this._uid;
        },
        state() {
            return this.v ? !this.v.$invalid : null;
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.required && !!this.v.$params.required) return 'Поле обязательно для заполнения';
            }
            return '';
        },
    },
};
</script>
